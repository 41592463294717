<template>
  <div class="buttons">

        <button v-if="!isRunning && !completed" class="button is-primary" @click.prevent="startHandle">
          <span class="icon">
            <unicon name="play" fill="#fff" />
          </span>
          <span v-if="btnWithLabel">Start</span>
        </button>

        <div v-if="isRunning" class="mr-2">

          <button v-if="pause" class="button is-info " @click.prevent="resumeTimer">
            <span class="icon">
              <unicon name="play" fill="#000" />
            </span>
            <span v-if="btnWithLabel">Resume</span>
          </button>

          <button v-if="!pause" class="button is-info "  @click.prevent="pauseTimer">
            <span class="icon">
              <unicon name="pause-circle" fill="#000" />
            </span>
            <span v-if="btnWithLabel">Pause</span>
          </button>

        </div>

        <button v-if="isRunning && !completed" class="button is-warning" @click.prevent="onCompleteHandle">
          <span class="icon">
              <unicon name="stop-circle" fill="#fff" />
          </span>
          <span v-if="btnWithLabel">Stop</span>
        </button>

        <button v-if="!isRunning && completed" class="button is-success" @click.prevent="onSubmit">
          <span class="icon">
              <unicon name="check" fill="#fff" />
          </span>
          <span v-if="btnWithLabel">Submit Time</span>
        </button>
    </div>
</template>


<script>
import useTimeTracking from '@/use/useTimeTracking'
import { onMounted } from 'vue';

export default {
  props:{
    btnWithLabel: {
      type: Boolean,
      default: true
    },
    issue: {
      type: Object,
    }
  },
  setup (props, { emit }) {
    const {
      pause,
      isRunning,
      completed,
      toggleTimer,
      startTimer,
      pauseTimer,
      resumeTimer,
      onCompleteHandle,
      onSubmit,
      initData,
      // initIssueData
    } = useTimeTracking();

    onMounted(()=> {
      initData()
      // initIssueData(props.issue, 3)
    })


    function startHandle () {
      // initIssueData(props.issue, 3)
      startTimer()
    }

    return {
      pause,
      isRunning,
      completed,
      toggleTimer,
      pauseTimer,
      resumeTimer,
      onCompleteHandle,
      onSubmit,
      startHandle
    }



  }
}
</script>

<style lang="scss" scoped>

</style>